import { Typography } from "@mui/material";
import Div from "../../../components/Div";

interface IAnsNo {
  number: string;
}
export default function AnsNo(props: IAnsNo) {
  return (
    <Div
      centered
      sx={{ bgcolor: "transparent", margin: 0, padding: 0 }}
      cornered
    >
      <Typography variant={"h4"} padding={2}>
        {props.number}
      </Typography>
    </Div>
  );
}
