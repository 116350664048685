import { Stack, Typography, Box, Button } from "@mui/material";
import { NavLink } from "react-router-dom";
import { Keys } from "../../common/Keys";
import { dark, light } from "../../themes/CustomTheme";

interface INotFound {}
export default function NotFound(props: INotFound) {
    const theme = localStorage.getItem(Keys.DARK_THEME) ? dark : light;
  return (
    <Stack alignItems={"center"}spacing={3}>
      <Stack alignItems={"center"} >
      <Typography variant="h1"  fontSize={"20rem"} fontWeight={"bold"} >404</Typography>
      <Typography variant="h5" align="center">Page not found!</Typography>
      </Stack>
      <Box>
        <NavLink to="/"> <Button variant="outlined"> Back to Home    </Button></NavLink>
      </Box>
    </Stack>
  );
}
