import { useEffect, useState } from "react";
import Div from "./Div";

interface IElementSelector {
  index: number;
  selectedAnswerIndex: number | null;
  setSelectedAnswerIndex: React.Dispatch<React.SetStateAction<number | null>>;
  children: JSX.Element;
  width?: string | number;
  selector: "filled" | "outline";
  selectorColor: string;
  fontColor?: string;
}
export default function ElementSelector(props: IElementSelector) {
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    setSelected(props.selectedAnswerIndex === props.index);
  }, [props.selectedAnswerIndex]);

  const handleSelectAnswer = () => {
    props.setSelectedAnswerIndex(props.index);
  };
  return (
    <Div
      sx={{
        width: props.width ? props.width : "",
        cursor: "pointer",
        padding: 0,
        display: "flex",
        justifyContent: "left",
        alignItems: "center",
        gap: 3,
        bgcolor:
          selected && props.selector === "filled" ? props.selectorColor : "",
        color: selected && props.selector === "filled" ? props.fontColor : "",
        borderBottom: props.selector === "outline" ? "4px solid" : "",
        borderColor:
          selected && props.selector === "outline"
            ? props.selectorColor
            : "transparent",
        opacity: selected ? 0.9 : 1,
      }}
      onClick={handleSelectAnswer}
      elevation={1}
    >
      {props.children}
    </Div>
  );
}
