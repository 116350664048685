import { useEffect, useState } from "react";
import { setQuestions } from "../../store/quiz";
import { useDispatch } from "react-redux";
import { IQuiz } from "../../assets/quiz";
import { getQuizAllQuestions } from "../../services/v1/quizzesService";
import { IResponse } from "../../types/ResponseType";

export const useQuizAllQuestions = (id: string) => {
  const [data, setData] = useState<IResponse<IQuiz>>();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      setIsError(false);
      setIsLoading(true);
      try {
        const data = await getQuizAllQuestions(id);
        setData(data);
        if(data.payload){
          dispatch(setQuestions(data.payload.questions));
        }
      } catch (err) {
        setIsError(true);
      }
      setIsLoading(false);
    })();
  }, []);

  return {
    data,
    isLoading,
    isError,
  };
};
