import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

export const useQuestionStateSelector = () => {
  const state = useSelector((state: RootState) => state.entities.questionState);
  return state;
};

export const selectQuestionState = (state: RootState) =>
  state.entities.questionState.name;
