import { useEffect, useState } from "react";
import { IResponse } from "../../types/ResponseType";
import { JWTToken, LogIn } from "../../types/AuthTypes";
import { logIn as logInService } from "../../services/v1/authService";
import { TokenCreate } from "../../types/TokenType";

export const useLogIn = () => {
  const [data, setData] = useState<IResponse<JWTToken>>();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const logIn = async (auth:LogIn) => {
    setIsError(false);
    setIsLoading(true);
    try {
      const data = await logInService(auth);
      setData(data);
    } catch (err) {
      setIsError(true);
    }
    setIsLoading(false);
  };

  return {
    data,
    isLoading,
    isError,
    logIn
  };
};
