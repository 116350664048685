import { combineReducers } from "redux";
import themeReducer from "./theme";
import stateReducer from "./state";
import quizReducer from "./quiz";
import quizSelectorReducer from "./quizSelector";
import questionState from "./questionState";
import itineraryState from "./itinerary";
import authenticationState from "./authentication";

export default combineReducers({
  theme: themeReducer,
  state: stateReducer,
  quiz: quizReducer,
  quizSelector: quizSelectorReducer,
  questionState: questionState,
  itinerary: itineraryState,
  authentication: authenticationState,
});
