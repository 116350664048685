import { createSlice } from "@reduxjs/toolkit";

const quizSelectorSlice = createSlice({
  name: "quizSelectorSchema",
  initialState: { 
    selected:false
},
  reducers: {
    on: (quizSelectorSchema) => {
        quizSelectorSchema.selected = true;
    },
    off: (quizSelectorSchema) => {
        quizSelectorSchema.selected = false;
    },
  },
});
export const { on, off } = quizSelectorSlice.actions;
export default quizSelectorSlice.reducer;
