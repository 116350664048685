import { Box, Paper, SxProps, Theme } from "@mui/material";

type IDiv = {
  children?: any;
  width?: number | string;
  height?: number | string;
  centered?: boolean;
  contentCentered?: boolean;
  sx?: SxProps<Theme> | undefined;
  className?: string | undefined;
  cornered?: boolean;
  overflow?: boolean;
  elevation?: number;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
};
export default function Div(props: IDiv) {
  return (
    <Paper
      component={"div"}
      onClick={props.onClick}
      className={props.className}
      elevation={props.elevation ? props.elevation : 0}
      sx={{
        display: props.contentCentered === true ? "flex" : "",
        justifyContent: props.contentCentered === true ? "center" : "",
        alignItems: props.contentCentered === true ? "center" : "",
        padding: 2,
        width: props.width && props.width,
        height: props.height && props.height,
        margin: props.centered ? "auto" : "",
        borderRadius: props.cornered ? 0 : 3,
        overflow: props.overflow ? "auto" : "hidden",
        ...props.sx,
      }}
    >
      {props.children}
    </Paper>
  );
}
