import { createSlice } from "@reduxjs/toolkit";
import { Keys } from "../common/Keys";

const themeSlice = createSlice({
  name: "themeSchema",
  initialState: {light: localStorage.getItem(Keys.THEME) ? false : true },
  reducers: {
    switchToLight: (themeSchema) => {
      localStorage.removeItem(Keys.THEME);
      themeSchema.light = true;
    },
    switchToDark: (themeSchema) => {
      localStorage.setItem(Keys.THEME, Keys.DARK_THEME);
      themeSchema.light = false;
    },
  },
});
export const { switchToLight, switchToDark } = themeSlice.actions;
export default themeSlice.reducer;
