import { Outlet } from "react-router-dom";
import Div from "../Div";

interface IAuthenticationRelatedLayout {
  children?: JSX.Element;
}
export default function AuthenticationRelatedLayout(
  props: IAuthenticationRelatedLayout
) {
  return (
    <Div
      sx={{ backgroundColor: "background.default" }}
      centered
      contentCentered
    >
      <Div centered width={400} sx={{ backgroundColor: "transparent" }} >
        {props.children}
        <Outlet/>
      </Div>
    </Div>
  );
}
