import { useState } from "react";
import { IResponse } from "../../types/ResponseType";
import { TokenCreate, TokenResponse } from "../../types/TokenType";
import { createToken } from "../../services/v1/tokenService";

export const useTokenCreate = () => {
  const [data, setData] = useState<IResponse<TokenResponse>>();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const create = async (accessToken: string, params: TokenCreate) => {
    setIsError(false);
    setIsLoading(true);
    try {
      const data = await createToken(accessToken, params);
      setData(data);
    } catch (err) {
      setIsError(true);
    }
    setIsLoading(false);
  };

  return {
    data,
    isLoading,
    isError,
    create,
  };
};
