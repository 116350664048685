import { Typography } from "@mui/material";

interface ITitle {
  children?: string;
  color?: any;
  align?: "center" | "left" | "right";
}
export default function Title(props: ITitle) {
  return (
    <>
      <Typography
        align={props.align}
        color={props.color}
        variant={"h5"}
        fontWeight={"bold"}
        mt={2}
      >
        {props.children}
      </Typography>
    </>
  );
}
