import { createTheme } from "@mui/material";

export const light = createTheme({
  palette: {
    mode: "light",
    //   primary: {
    //     main: "#0D55BB",
    //   },
    //   secondary: {
    //     main: "#00FF01",
    //   },
    //   background: {
    //     default: "#F1F1F1",
    //     paper: "#FFFAFA",
    //   },
    //   text: {
    //     primary: "#242632",
    //     disabled: "#e3e2e2", //F1F1F1
    //   },
    //   info: {
    //     main: "#007399",
    //   },
    //   success: {
    //     main: "#0C7B61",
    //   },
    //   warning: {
    //     main: "#b38600",
    //   },
    //   error: {
    //     main: "#660000",
    //   },
  },
});

export const dark = createTheme({
  palette: {
    mode: "dark",
    // primary: {
    //   main: "#0D55BB", //#1082f4
    // },
    // secondary: {
    //   main: "#00FF01", // ##00FF01
    // },
    // background: {
    //   default: "#1C2023",
    //   paper: "#242632",
    // },
    // text: {
    //   primary: "#FFFAFA",
    //   disabled: "#e3e2e2",
    // },
    // info: {
    //   main: "#007399",
    // },
    // success: {
    //   main: "#0C7B61",
    // },
    // warning: {
    //   main: "#b38600",
    // },
    // error: {
    //   main: "#660000",
    // },
  },
});
