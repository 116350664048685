import { useParams } from "react-router";
import { useEffect, useState } from "react";
import Question from "../quiz/components/Question";
import { useQuizAllQuestions } from "../../hooks/query/useQuizzAllQuestions";

export default function QuizTest() {
  const { id } = useParams();
  const { data: quiz, isError, isLoading } = useQuizAllQuestions(id || "1");

  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  useEffect(() => {
    const handleBeforeUnload = (event: { preventDefault: () => void }) => {
      event.preventDefault();
      return "Are you sure you want to leave this page?";
    };

    window.onbeforeunload = handleBeforeUnload;

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  useEffect(() => {
  }, [quiz]);

  if (isLoading) {
    return <>Loading...</>;
  }
  if (isError) {
    return <>Error...</>;
  }
  if (!isLoading && !isError && quiz?.payload && quiz.payload.questions.length === 0) {
    return <>No questions...</>;
  }
  if (!isLoading && !isError && quiz?.payload)
    return (
      <>
        {quiz.payload.questions.map((question) => {
          return (
            <Question
              setSelectedIndex={setSelectedIndex}
              question={question}
              selectedIndex={selectedIndex}
              correctIndex
            />
          );
        })}
      </>
    );
  return <>Error...</>;
}
