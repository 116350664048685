import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

export const useThemeSelector = () => {
  const theme = useSelector((state: RootState) => state.entities.theme);
  return theme;
};

export const useThemeSelectorIsLight = () => {
  const theme = useSelector((state: RootState) => state.entities.theme);
  return theme.light;
};