import { createSlice } from "@reduxjs/toolkit";


export interface ItineraryPlace{
    name: string;
    address:string;
  }

interface IAddItinerarySlice{
    itineraryPlaces:ItineraryPlace[]; 
}

const initialState: IAddItinerarySlice = {
    itineraryPlaces: []
};

const addItinerarySlice = createSlice({
    name: "itinerarySchema",
    initialState,
    reducers: {

    addItinerary: (itinerarySchema, action) => {
        const ip = [...itinerarySchema.itineraryPlaces];
       ip.push(action.payload);
        return {
          ...itinerarySchema,
            itineraryPlaces: ip,
        };
      },
    }
});

export const {addItinerary} = addItinerarySlice.actions;
export default addItinerarySlice.reducer;