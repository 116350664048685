import { Alert, AlertColor, AlertTitle, IconButton } from "@mui/material";
import { useState } from "react";
import { IconClose } from "../assets/Icons";

type IAd = {
  close?: boolean;
  severity?: AlertColor;
  title: string;
  desc?: string;
  children?: any;
};
export default function Ad(props: IAd) {
  const [open, setOpen] = useState(true);

  return (
    <>
      {open && (
        <Alert
          severity={props.severity || "info"}
          action={
            props.close && (
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <IconClose />
              </IconButton>
            )
          }
        >
          <>
            <AlertTitle>{props.title}</AlertTitle>
            {props.desc}
            {props.children}
          </>
        </Alert>
      )}
    </>
  );
}
