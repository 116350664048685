import { Button, Stack, TextField } from "@mui/material";
import ModalBackground from "../../../components/ModalBackground";
import { IconContentCopy, IconContentPaste } from "../../../assets/Icons";
import Ad from "../../../components/Ad";
import TooltipWithIcon from "../../../components/TooltipWithIcon";
import { useState } from "react";

interface ITokenModal {
  token: string;
  daysValidity: number;
}
export default function TokenModal(props: ITokenModal) {

  const [isCopied, setIsCopied] = useState(false);

  const message = () => {
    return `Validity ${props.daysValidity} day${
      props.daysValidity > 1 ? "s" : ""
    }`;
  };

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(props.token)
      .then(() => {
        setIsCopied(true);
      })
      .catch((err) => console.error("Could not copy text to clipboard:", err));
  };

  const regenerate = () => {
    window.location.reload();
  };

  return (
    <ModalBackground>
      <Stack spacing={2}>
        <Ad title={message()}></Ad>
        <Stack sx={{ maxWidth: 500 }} direction={"row"}>
          <TextField type="text" value={props.token} fullWidth sx={{minWidth:300}} />
          <Button onClick={copyToClipboard}>
            <TooltipWithIcon
              info={isCopied ? "Copied to clipboard" : "Copy to clipboard"}
              icon={
                isCopied ? (
                  <IconContentPaste sx={{ color: "success.main" }} />
                ) : (
                  <IconContentCopy />
                )
              }
            />
          </Button>
        </Stack>
        <Button variant="contained" onClick={regenerate}>
          Regenerate
        </Button>
      </Stack>
    </ModalBackground>
  );
}
