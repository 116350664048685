import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import ElementSelector from "./ElementSelector";

interface IGroupFormSelector {
  elements: JSX.Element[];
  fitContentElement?: boolean;
  xs: number;
  sm: number;
  md: number;
  selector: "filled" | "outline";
  selectorColor: string;
  fontColor?: string;
  setIndex: React.Dispatch<React.SetStateAction<number | null>>;
  selectedIndex: number | null;
}

export default function GroupFormSelector(props: IGroupFormSelector) {
  // useEffect(() => {
  //   props.setIndex && props.setIndex(selectedAnswerIndex);
  // }, [selectedAnswerIndex]);
  return (
    <Grid
      container
      spacing={2}
      display="flex"
      alignItems={"center"}
      justifyContent="space-between"
      width={"100%"}
    >
      {props.elements.map((element, index) => {
        return (
          <Grid item xs={props.xs} sm={props.sm} md={props.md} key={index}>
            <ElementSelector
              selector={props.selector}
              width={props.fitContentElement ? "fit-content" : "auto"}
              index={index}
              selectedAnswerIndex={props.selectedIndex}
              setSelectedAnswerIndex={props.setIndex}
              selectorColor={props.selectorColor}
              fontColor={props.fontColor}
            >
              {element}
            </ElementSelector>
          </Grid>
        );
      })}
    </Grid>
  );
}
