import { createSlice } from "@reduxjs/toolkit";
import StateConsts from "../consts/StateConsts";
import QuestionStateConsts from "../consts/QuestionStateConsts";
const initialState = {
  name: QuestionStateConsts.VERIFY_STATE,
};
const stateSlice = createSlice({
  name: "questionStateSchema",
  initialState: initialState,
  reducers: {
    setVerifyState: (stateSchema) => {
      stateSchema.name = QuestionStateConsts.VERIFY_STATE;
    },
    setNextState: (stateSchema) => {
      stateSchema.name = QuestionStateConsts.NEXT_STATE;
    },
    setFinishState: (stateSchema) => {
      stateSchema.name = QuestionStateConsts.FINISH_STATE;
    },
  },
});
export const { setVerifyState, setNextState, setFinishState } =
  stateSlice.actions;
export default stateSlice.reducer;
