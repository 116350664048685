import { JWTToken, LogIn } from "../../types/AuthTypes";
import { IResponse } from "../../types/ResponseType";
import axiosInstance from "./axiosInstance";




export const logIn = async (logIn: LogIn) => {
  const { data } = await axiosInstance.post<IResponse<JWTToken>>(`/auth/login`, logIn);
  return data;
};
